import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';

const RatingsTable = ({data}) => {
    const [columns, setColumns] = useState([]);

    useEffect(() => {
        let columnsData = [
            {
                name: 'Miqaat Name',
                selector: (row) => row.miqaatName || '-', 
                minWidth: '150px', 
                maxWidth: '180px'
            },
            ...data[0]?.ratings.map((item, index)=> ({
                name: item.ratingCriteriaDescription,
                selector: (row) => row.ratings[index].ratingOptionDescription || '-', 
                minWidth: '170px', 
                maxWidth: '200px'
            })),
            {
                name: 'Total Attendance',
                selector: (row) => row.attendanceCount ? `${row.attendanceCount} days` : '-', 
                minWidth: '140px', 
                maxWidth: '140px'
            },
            {
                name: 'Notes',
                selector: (row) => row.notes || '-', 
                minWidth: '180px', 
            },
        ];
        setColumns(columnsData)
    },[])

    return (
        <div className='ratings-table table-wrapper'>
            <DataTable
              data={data?.length > 0 ? data : [{ miqaatName: "No Data Found" }]}
              columns={columns}
              fixedHeader={true}
              fixedHeaderScrollHeight='534px'/>
        </div>
    )
}

export default RatingsTable
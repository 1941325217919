import { Button, IconButton } from "@mui/material";
import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import { get } from "lodash";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
const FilterData = ({
  columnName,
  keyName,
  ref,
  filterType,
  options,
  handleFilterChange,
  data,
  filterValues,
  columns,
  pagination,
  noDataColKey,
  width,
  getFilteredData,
  match,
}) => {
  const isValidDate = (date) => {
    // Check if the date can be parsed
    const parsedDate = Date.parse(date);
    return !isNaN(parsedDate);
  };
  
  const formatDate = (date) => {
    const [day, month, year] = date.split('/');
    return `${year}/${month}/${day}`;
  };

  const filteredData = data?.filter((item) => {
    return Object.keys(filterValues).every((col) => {
      const filterValue = filterValues[col];
      const cellValue = item[col];
      if (
        typeof filterValue === "undefined" ||
        filterValue === "" ||
        filterValue?.value === ""
      ) {
        return true;
      }

      if (
        typeof cellValue === "string" ||
        typeof cellValue === "number" ||
        typeof cellValue === "boolean" ||
        typeof cellValue === "radio"
      ) {
        if (filterValue.type) {
          if (filterValue.type === "select" || filterValue.type === "radio") {
            return (
              cellValue.toString().toLowerCase() ===
              filterValue.value.toString().toLowerCase()
            );
          }
          if (filterValue.type === 'datepicker') {
              const formattedFilterValue = formatDate(filterValue.value);
              const formattedCellValue = cellValue;
              return formattedCellValue === formattedFilterValue;
          }
        }

        return cellValue
          .toString()
          .toLowerCase()
          .trim()
          .includes(filterValue.toString().toLowerCase().trim());
      }
      if (Array.isArray(cellValue)) {
        if (get(cellValue, "0.value")) {
          return cellValue.some((cell) => cell.value == filterValue.value);
        } else if (filterValue.value && cellValue.includes(filterValue.value)) {
          return true;
        }
        const found = cellValue.find(
          (cell) => cell?.value === filterValue.value
        );
        return found !== undefined;
      }

      return false;
    });
  });

  useEffect(() => {
    if (filteredData && filteredData !== undefined) {
      getFilteredData(filteredData);
    }
  }, [filterValues]);

  if (filterType === "select") {
    return (
      <div className="column-header">
        {columnName}
        <select
          style={{ marginTop: "6px", width: width }}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) =>
            handleFilterChange(keyName, e.target.value, "select")
          }
          className="input-field "
        >
          <option value="">Select</option>
          {options &&
            options?.map((option) => (
              <option value={option?.value}>{option?.label}</option>
            ))}
        </select>
      </div>
    );
  }
  if (filterType === "text" || filterType === "number") {
    return (
      <>
        {columnName}
        <input
          type={filterType === "number" ? "number" : "text"}
          style={{ marginTop: "6px", width: "95%" }}
          name={keyName}
          className="input-field"
          value={keyName && filterValues ? filterValues[keyName] : null}
          onBlur={(e) => handleFilterChange(e.target.name, e.target.value)}
          onChange={(e) => handleFilterChange(e.target.name, e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleFilterChange(e.target.name, e.target.value);
            }
          }}
          onClick={(e) => e.stopPropagation()}
        />
      </>
    );
  }
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <DataTable
        data={
          filteredData?.length > 0
            ? filteredData
            : [{ [noDataColKey ? noDataColKey : "itsid"]: "No Data Found" }]
        }
        columns={columns}
        fixedHeader={pagination}
        fixedHeaderScrollHeight={pagination && "482px"}
        ref={ref}
        // subHeader
        pagination={false}
        subHeaderAlign="left"
      />
      <div
        style={{ position: "fixed", bottom: 10, right: 20, marginBottom: "10px" }}
      >
        <IconButton aria-label="delete" size="medium" onClick={scrollTop} variant="contained" color="success" style={{background: "#F2F6EE", boxShadow: '0px 2px 8px rgba(0,0,0,0.2)'}}>
          <KeyboardArrowUpIcon />
        </IconButton>
      </div>
      {/* <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '10px' }}>
        <Button onClick={scrollTop} variant='contained' color='success'>Scroll top</Button>
      </div> */}
    </>
  );
};

export default FilterData;
